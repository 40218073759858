import ArticleDetail from "../pages/article/ArticleDetail";
import ArticlePage from "../pages/article/ArticlePage";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import Registration from "../pages/auth/Registration";
import Cart from "../pages/cart/Cart";
import Checkout from "../pages/cart/Checkout";
import MainPage from "../pages/main/MainPage";
import Catalog from "../pages/products/Catalog";
import ProductDetail from "../pages/products/ProductDetail";
import User from "../pages/user/User";
import { BASKET_PAGE_ROUTE, BLOG_DETAIL_PAGE_ROUTE, BLOG_PAGE_ROUTE, CATALOG_CATEGORY_PAGE_ROUTE, CATALOG_PAGE_ROUTE, CHECKOUT_PAGE_ROUTE, FORGOT_PASSWORD_ROUTE, LOGIN_PAGE_ROUTE, MAIN_PAGE_ROUTE, PRODUCTS_DETAIL_PAGE_ROUTE, PRODUCTS_PAGE_ROUTE, REGISTRATION_PAGE_ROUTE, USER_PAGE_ROUTE } from "./Constants";

export const publicRoutes = [
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    {
        path: '/',
        Component: MainPage
    },
    {
        path: BLOG_DETAIL_PAGE_ROUTE,
        Component: ArticleDetail
    },
    {
        path: BLOG_PAGE_ROUTE,
        Component: ArticlePage
    },
    {
        path: PRODUCTS_DETAIL_PAGE_ROUTE,
        Component: ProductDetail
    },
    {
        path: CATALOG_PAGE_ROUTE,
        Component: Catalog
    },
    {
        path: BASKET_PAGE_ROUTE,
        Component: Cart
    },
    {
        path: LOGIN_PAGE_ROUTE,
        Component: Login
    },
    {
        path: REGISTRATION_PAGE_ROUTE,
        Component: Registration
    },
    {
        path: FORGOT_PASSWORD_ROUTE,
        Component: ForgotPassword
    },
    {
        path: USER_PAGE_ROUTE,
        Component: User
    },
    {
        path: CHECKOUT_PAGE_ROUTE,
        Component: Checkout
    },
    {
        path: CATALOG_CATEGORY_PAGE_ROUTE,
        Component: Catalog
    },
]

import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import { NavLink } from 'react-router-dom';
import HeaderMenu from './HeaderMenu';
import UserIcon from '../simple/UserIcon';

const Header = observer(() => {
    const {app} = useContext(Context);
    const [sticky, setSticky] = useState();

    const [openMenu, setOpenMenu] = useState();

    const [logo, setLogo] = useState();

    useEffect(() => {
        if(app.settings.length >= 1){
            setLogo(app.settings.find(it => it.type === "logo"));
        }
    }, [app.settings])

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 120){
            setSticky(true)
        }else{
            setSticky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

  return (
    <header id="header-2" className={sticky ? "header navik-header header-shadow center-menu-2 sticky" : "header navik-header header-shadow center-menu-2"}>
        <div className="container">
            <div className="navik-header-container">              
                {logo && 
                    <div className="logo">
                        <NavLink to={`/${app.lang}`}><img src={'https://solution-0002.panama.kz' + logo.value} alt={logo.name}/></NavLink>
                    </div>
                }               
                <div className={openMenu ? "burger-menu menu-open" : "burger-menu"} onClick={() => setOpenMenu(!openMenu)}>
                    <div className="line-menu line-half first-line"></div>
                    <div className="line-menu"></div>
                    <div className="line-menu line-half last-line"></div>
                </div>
                <HeaderMenu logo={logo} sticky={sticky} openMenu={openMenu} setOpenMenu={setOpenMenu}/>
            </div>	
        </div>     
    </header>
  )
})

export default Header
import React, { useContext, useEffect, useState } from 'react'
import UserServices from '../../../services/general/userServices';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import useFetchInfo from '../../../hooks/useFetchInfo.hook';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';

const EditAddressModal = observer(({show, setShow, id, fetchItems}) => {
    const [visible, setVisible] = useState(false);

    const {app} = useContext(Context);

    const {info, loading} = useFetchInfo(UserServices.addresses.view, id);

    const handleClose = () => setShow(false);

    const {handleSubmit, register, setValue, formState: {errors}} = useForm();

    useEffect(() => {
        if (info) {
            const {json_address} = info;
            for (const [key, value] of Object.entries(json_address)) {
                json_address[key] !== null && setValue(key, String(value));
            }
        }
    }, [info, setValue])

    const CustomSubmit = async (data) => {
        const newData = {
            json_address: {
                ...data
            },
            full_address: `${data.city}, ${app.localizationsItems?.street} ${data.street} ${data.house}, ${app.localizationsItems?.apartment} ${data.apartment}, ${app.localizationsItems?.entrance} ${data.entrance}, ${app.localizationsItems?.floor} ${data.floor}, ${app.localizationsItems?.note} ${data.note}`
        }

        const res = await UserServices.addresses.update(id, newData);

        if (res.statusCode === 200) {
            setVisible(true);
            fetchItems();
            setTimeout(() => {
                setVisible(false);
            }, 3000);
        }
    };
  return (
	<Modal show={show} onHide={handleClose} className="modal fade theme-modal" centered>
			<Modal.Header>
				<h5 className="modal-title">{app.localizationsItems?.edit_address}</h5>
				<button type="button" className="btn-close" onClick={handleClose}>
					<i className="fa-solid fa-xmark"></i>
				</button>
			</Modal.Header>
            <form onSubmit={handleSubmit(CustomSubmit)}>
                <Modal.Body>
                    <div className="form-floating mb-4 theme-form-floating">
                            <input type="text" className="form-control" {...register('city')} placeholder={app.localizationsItems?.city}/>
                            <label>{app.localizationsItems?.city}</label>
                        </div>
                        <div className="form-floating mb-4 theme-form-floating">
                            <input type="text" className="form-control" {...register('street')} placeholder={app.localizationsItems?.street}/>
                            <label>{app.localizationsItems?.street}</label>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-floating mb-4 theme-form-floating">
                                    <input type="text" className="form-control" {...register('house')} placeholder={app.localizationsItems?.house}/>
                                    <label>{app.localizationsItems?.house}</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-floating mb-4 theme-form-floating">
                                    <input type="text" className="form-control" {...register('apartment')} placeholder={app.localizationsItems?.apartment}/>
                                    <label>{app.localizationsItems?.apartment}</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-floating mb-4 theme-form-floating">
                                    <input type="text" className="form-control" {...register('entrance')} placeholder={app.localizationsItems?.entrance}/>
                                    <label>{app.localizationsItems?.entrance}</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-floating mb-4 theme-form-floating">
                                    <input type="text" className="form-control" {...register('floor')} placeholder={app.localizationsItems?.floor}/>
                                    <label>{app.localizationsItems?.floor}</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-floating mb-4 theme-form-floating">
                            <textarea type="text" className="form-control" {...register('note')} placeholder={app.localizationsItems?.note}/>
                            <label>{app.localizationsItems?.note}</label>
                        </div>
                        {visible && 
                            <p className='success-address'>
                                <i className='fa fa-check-circle'></i>
                                {app.localizationsItems?.new_data}
                            </p>
                        }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-tra-red" onClick={handleClose}>{app.localizationsItems?.cancel}</button>
                    <button className="btn btn-red">{app.localizationsItems?.save}</button>
                </Modal.Footer>
            </form>
	</Modal>
  )
})

export default EditAddressModal
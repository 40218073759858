import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Context } from '../..'
import { NavLink } from 'react-router-dom';

const PageTop = observer(({item, parent}) => {
    const {app} = useContext(Context);

  return (
    <div id="product-page" className="page-hero-section division" style={{backgroundImage: `url(https://solution-0002.panama.kz${item.detail_image})`}}>
        <div className="container">	
            <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                    <div className="hero-txt text-center white-color">
                        <div id="breadcrumb">
                            <div className="row">						
                                <div className="col">
                                    <div className="breadcrumb-nav">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><NavLink to={`/${app.lang}`}>{app.localizationsItems?.main}</NavLink></li>
                                                {parent && <li className="breadcrumb-item"><NavLink to={parent.url}>{parent.title}</NavLink></li>}
                                                <li className="breadcrumb-item active" aria-current="page">{item.title}</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <h2 className="h2-xl">{item.title}</h2>
                    </div>
                </div>	
            </div>
        </div>
        <div className='back-black'></div>
    </div>
  )
})

export default PageTop
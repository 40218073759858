import React from 'react'
import OwlCarousel from 'react-owl-carousel';

const MainBanner = ({elem}) => {
    const settings = {
        items: 1,
        nav: false,
        dots: true,
        loop: true,
        stagePadding: 250,
        margin: 30,
        responsive: {
            1200: {stagePadding: 200},
            992: {stagePadding: 150, margin: 20,},
            768: {stagePadding: 100},
            480: {stagePadding: 0, margin: 0,},
            380: {stagePadding: 0},
            0: {stagePadding: 0}
        }
    }
    
  return (
    elem.elements?.length >= 1 ? 
    <div id="hero-8" className="hero-section division">
        <div>
            <OwlCarousel className="slides owl-carousel" {...settings}>
                {elem.elements?.map((card, index) =>
                    <div  className='slides-item' key={index}>
                        <img src={'https://solution-0002.panama.kz' + card.front_image} alt="slide-background"/>
                    </div>
                )}
            </OwlCarousel>
        </div>	  
    </div>
    :
    <></>
  )
}

export default MainBanner
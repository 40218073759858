import React, { useContext, useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './App.scss';
import Header from './components/complex/Header'
import Footer from './components/complex/Footer';
import { observer } from 'mobx-react-lite';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './navigation/AppRouter';
import { Context } from '.';
import './i18n/i18n'
import AppServices from './services/general/appServices';
import UserServices from './services/general/userServices';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './hooks/scrollToTop';

const App = observer(() => {
    const {app, user} = useContext(Context);
    const [loading, setLoading] = useState();

    const checkAuth = async () => {
        if(user.token){
            const res = await UserServices.profile.index();
            if(res.statusCode === 200){
                
            }else{
                user.removeToken();
            }
        }
    }

    const fetchdata = async () => {
        setLoading(true);
        const res = await AppServices.settings();

        if(res.statusCode === 200){
            let settings = res.content;
            app.setSettings(settings);

            let userEnabled = settings.find(it => it.type === "user-enabled");
            userEnabled && app.setUserCabinet(userEnabled.value);

            let registerByPhone = settings.find(it => it.type === "register-by-phone")?.value;
            registerByPhone && app.setRegisterByPhone(registerByPhone);

            let bonusesEnabled = settings.find(it => it.type === "bonuses_enabled")?.value;
            app.setBonuses(bonusesEnabled);

            let head = settings.find(it => it.type === "head")?.value;
            let bottom = settings.find(it => it.type === "bottom")?.value;
            let header = settings.find(it => it.type === "header")?.value;
            
            if(head){
                head.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.head.append(divFragment);
                });
            }

            if(header){
                header.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.body.append(divFragment);
                });
            }

            if(bottom){
                bottom.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.body.prepend(divFragment);
                });
            }
        }

        const res2 = await AppServices.localizations();

        if(res2.statusCode === 200){
            let temp;
            if(res2.content.find(it => it.slug === localStorage.getItem('i18nextLng'))){
                temp = localStorage.getItem('i18nextLng');
            }else{
                temp = res2.content[0].slug
                localStorage.setItem('i18nextLng', temp)
            }
            app.setLang(temp)
            app.setLocalizationsItems(res2.content.find(it => it.slug === temp).description)
            app.setLanguages(res2.content)
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchdata();
        checkAuth();
    }, [app.lang])

  return (
    <>
        {loading ?
            <div id="loader-wrapper">
                <div id="loader">
                    <div className="cssload-spinner">
                        <div className="cssload-ball cssload-ball-1"></div>
                        <div className="cssload-ball cssload-ball-2"></div>
                        <div className="cssload-ball cssload-ball-3"></div>
                        <div className="cssload-ball cssload-ball-4"></div>
                    </div>
                </div>
            </div>
            :
            <BrowserRouter>
                <ScrollToTop />
                <Header/>
                <AppRouter/>
                <Footer/>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </BrowserRouter>
        }
    </>
  )
})

export default App
import React, { useContext } from 'react'
import PageTop from '../../components/simple/PageTop'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import RegistrationContainer from '../../components/complex/RegistrationContainer'

const Registration = observer(() => {
    const {app} = useContext(Context);
    
    const item = {
        title: app.localizationsItems?.regis
    }

  return (
    <div>
        <PageTop item={item}/>
        <RegistrationContainer/>
    </div>
  )
})

export default Registration
import React, { useContext } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import CatalogServices from '../../services/general/catalogServices'
import { useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import img from "../../images/allCategories.png"

const CatalogCategories = observer(({setFilterData}) => {
    const {app} = useContext(Context);

    const navigate = useNavigate();
    let query = new URLSearchParams(useLocation().search);

    const {
        items,
        setFilterData: setCategoryFilter,
        loading: categoryLoading
    } = useFetchItems(CatalogServices.categories.index)

    const changeCategory = (name) => {
        let str = `?categories[]=${name}`;
        setFilterData(str);
        navigate({
            search: `?categories[]=${name}`
        });
    }

    const resetCategories = () => {
        setFilterData('');
        navigate({
            search: ``
        });
    }

  return (
    items &&
    <div className="col-lg-12 text-center mb-50">
        <ul className="tabs-1 ico-55 red-tabs clearfix">
            <li className={!query.get('categories[]') ? "tab-link active" : "tab-link"} onClick={resetCategories}>
                <span className='category-img'>
                    <img src={img} alt="" />    
                </span> 
                <h5 className="h5-sm">{app.localizationsItems?.all_text}</h5>
            </li>
            {items.items.map((card, index) =>
                <li className={query.get('categories[]') === card.slug ? "tab-link active" : "tab-link"} key={index} onClick={() => changeCategory(card.slug)}>
                    <span className='category-img'>
                        <img src={'https://solution-0002.panama.kz' + card.image} alt="" />    
                    </span> 
                    <h5 className="h5-sm">{card.title}</h5>
                </li>
            )}
        </ul>
    </div>
  )
})

export default CatalogCategories
import React, { useContext } from 'react'
import DeliveryOptionItem from './DeliveryOptionItem'
import useFetchItems from '../../hooks/useFetchItems'
import { Context } from '../..'
import UserServices from '../../services/general/userServices'
import { observer } from 'mobx-react-lite'

const DeliveryOption = observer(({setDelivery}) => {
    const {app} = useContext(Context);

    const {
        items:deliveries,
        loading
    } = useFetchItems(UserServices.cart.order.deliveries)

  return (
    <div className="checkout-box">
        <div className={loading ? "checkout-title isLoading isLoading-mini" : "checkout-title"}>
            <h4>{app.localizationsItems?.delivery_variant} <span className='red'>*</span></h4>
        </div>

        <div className="checkout-detail">
            <div className="row g-4">
                {deliveries?.map((card, index) =>
                    <div className="col-xxl-6" key={index}>
                        <DeliveryOptionItem card={card} setDelivery={setDelivery}/>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
})

export default DeliveryOption
import React, { useContext } from 'react'
import { useState } from 'react';
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import UserServices from '../../../services/general/userServices';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';

const AddAddressModal = observer(({show, setShow, fetchItems}) => {
    const [visible, setVisible] = useState(false);

    const {app} = useContext(Context);

    const handleClose = () => setShow(false);

    const {handleSubmit, register, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        const {city, street, house, apartment, entrance, floor, note} = data;

        let str = `${city} `
        str += street && `${app.localizationsItems?.street}: ${street} ${house}, `
        str += apartment && `${app.localizationsItems?.apartment}: ${apartment}, `
        str += entrance && `${app.localizationsItems?.entrance}: ${entrance}, `
        str += floor && `${app.localizationsItems?.floor}: ${floor}, `
        str += note && `${app.localizationsItems?.note}: ${note}`

        const newData = {
            json_address: {
                city, street, house, apartment, entrance, floor, note
            },
            full_address: str
        }

        const res = await UserServices.addresses.store(newData);
    
        if (res.statusCode === 200) {
            setVisible(true);
            fetchItems();
            setTimeout(() => {
                setVisible(false);
                handleClose();
            }, 3000);
        }
    };

  return (
	<Modal show={show} onHide={handleClose} className="modal fade theme-modal" centered>
			<Modal.Header>
				<h5 className="modal-title">{app.localizationsItems?.add_address}</h5>
				<button type="button" className="btn-close" onClick={handleClose}>
					<i className="fa-solid fa-xmark"></i>
				</button>
			</Modal.Header>
            <form onSubmit={handleSubmit(CustomSubmit)}>
                <Modal.Body>
                        <div className="form-floating mb-4 theme-form-floating">
                            <input type="text" className="form-control" {...register('city')} placeholder={app.localizationsItems?.city}/>
                            <label>{app.localizationsItems?.city}</label>
                        </div>
                        <div className="form-floating mb-4 theme-form-floating">
                            <input type="text" className="form-control" {...register('street')} placeholder={app.localizationsItems?.street}/>
                            <label>{app.localizationsItems?.street}</label>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-floating mb-4 theme-form-floating">
                                    <input type="text" className="form-control" {...register('house')} placeholder={app.localizationsItems?.house}/>
                                    <label>{app.localizationsItems?.house}</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-floating mb-4 theme-form-floating">
                                    <input type="text" className="form-control" {...register('apartment')} placeholder={app.localizationsItems?.apartment}/>
                                    <label>{app.localizationsItems?.apartment}</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-floating mb-4 theme-form-floating">
                                    <input type="text" className="form-control" {...register('entrance')} placeholder={app.localizationsItems?.entrance}/>
                                    <label>{app.localizationsItems?.entrance}</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-floating mb-4 theme-form-floating">
                                    <input type="text" className="form-control" {...register('floor')} placeholder={app.localizationsItems?.floor}/>
                                    <label>{app.localizationsItems?.floor}</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-floating mb-4 theme-form-floating">
                            <textarea type="text" className="form-control" {...register('note')} placeholder={app.localizationsItems?.note}/>
                            <label>{app.localizationsItems?.note}</label>
                        </div>
                        {visible && 
                            <p className='success-address'>
                                <i className='fa fa-check-circle'></i>
                                {app.localizationsItems?.address_added}
                            </p>
                        }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-tra-red" onClick={handleClose}>{app.localizationsItems?.cancel}</button>
                    <button className="btn btn-red">{app.localizationsItems?.save}</button>
                </Modal.Footer>
            </form>
	</Modal>
  )
})

export default AddAddressModal
import React from 'react'

const BigBanner = ({elem}) => {
    const banner = elem.elements[0]?.image

  return (
    banner && <div id="div-01" className="bg-scroll division" style={{backgroundImage: `url(https://solution-0002.panama.kz${banner})`}}></div>
  )
}

export default BigBanner
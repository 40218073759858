import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import PageTop from '../../components/simple/PageTop';
import CheckoutContainer from '../../components/complex/CheckoutContainer';
import { Context } from '../..';

const Checkout = observer(() => {
    const {app} = useContext(Context);

    const item = {
        title: app.localizationsItems?.checkout
    }

  return (
    <div>
        <PageTop item={item}/>
        <CheckoutContainer/>
    </div>
  )
})

export default Checkout
import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import { NavLink } from 'react-router-dom'
import CartIcon from '../simple/CartIcon'
import SearchIcon from '../simple/SearchIcon'
import UserIcon from '../simple/UserIcon'

const HeaderMenu = ({logo, sticky, openMenu, setOpenMenu}) => {
    const {
        items,
    } = useFetchItems(AppServices.menu, 'main-menu')

  return (
    <nav className="navik-menu menu-caret navik-yellow">
        <ul className={openMenu ? "top-list open" : "top-list"}>
            {sticky && <li className='li-none'>
                <NavLink to={`/`} className='pos-rel-logo' >
                    {logo && <img src={'https://solution-0002.panama.kz' + logo.value} alt="" />}
                </NavLink>
            </li>}
            {items?.map((card, index) =>
                <li key={index}>
                    <NavLink to={card.value} target={card.type} onClick={() => setOpenMenu(false)}>
                        {card.title}
                        {card.items && <span/>}
                    </NavLink>
                    {card.items &&
                        <ul>
                            {card.items.map((el, index) =>
                                <li key={index}>
                                    <NavLink to={el.value} target={el.type}>{el.title}</NavLink>
                                </li>
                            )}
                        </ul>
                    }
                </li>
            )}
        </ul>
        <ul className='list-icons'>
            <UserIcon/>
            <CartIcon/>
            <SearchIcon/>
        </ul>
    </nav>
  )
}

export default HeaderMenu
import React from 'react'
import { Context } from '../..';
import { useContext } from 'react';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import CatalogServices from '../../services/general/catalogServices';
import { Modal } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import AddToBasket from '../complex/AddToBasket';
import img from "../../images/placeholder.png"
import Utils from '../../services/utils';

const ProductViewModal = ({show, setShow, slug}) => {
    const {app} = useContext(Context);

    const handleClose = () => setShow(false);

    const {info, loading} = useFetchInfo(CatalogServices.products.view, slug);

  return (
    info &&
    <Modal className='product-view-modal' show={show} onHide={handleClose} centered size='xl'>
        <Modal.Body>
            <span className='product-modal-close' onClick={handleClose}>
                <i className='icon-close'></i>
            </span>
            <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-6'>
                    <img className='full-width-img' src={info.image ? 'https://solution-0002.panama.kz' + info.image : img} alt="" 
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                    />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 product-modal-wrapper">
                    <div className='product-modal'>
                        <div className='category'>{info.parent?.title}</div>
                        <NavLink to={`/${app.lang}/products/${info.slug}`}>
                            <h4>{info.title}</h4>
                        </NavLink>
                        <p>{info.subtitle}</p>
                        <h4 className="price">{Utils.numberWithSpaces(info.price)} {app.localizationsItems?.currency}</h4>
                        <div className='product_card_availability'>
                            {app.localizationsItems?.availability}: {
                                    info.availability ?
                                        <span className="text-success">{app.localizationsItems?.in_stock}</span>
                                    :
                                        <span className="text-danger">{app.localizationsItems?.not_available}</span>
                            }
                        </div>
                        {info.features &&
                            <ul className='features-list'>
                                {info.features.map((card, index) =>
                                    <li key={index}><span>{card.title}</span> <span>{card.value}</span></li>
                                )}
                            </ul>
                        }
                    </div>
                    <AddToBasket item={info}/>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default ProductViewModal
import React from 'react'
import ProductCard from '../simple/ProductCard'
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Context } from '../..';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import CatalogServices from '../../services/general/catalogServices';
import Pagination from './Pagination';
import CatalogCategories from './CatalogCategories';

const CatalogContainer = observer(({item, slug}) => {
    const {app, user} = useContext(Context);

    const navigate = useNavigate();

    let query = new URLSearchParams(useLocation().search);
    const [pageCount, setPageCount] = useState();
    const [params, setParams] = useState(query);
    const [loading, setLoading] = useState();
    const [items, setItems] = useState();

    const fetchProducts = async () => {
        setLoading(true);
        let str = user.wishlistUid ? `favorite_uid=${user.wishlistUid}&` : '';
        let str2 = query.toString();
        let str3 = slug ? `categories[]=${slug}&` : ``;

        const res = await CatalogServices.products.index(`${str3}${str}${str2}`);

        if(res.statusCode === 200){ 
            setItems(res.content);
            setPageCount(res.content?.total_pages);
        }
        setLoading(false);
    }

    useEffect(() => {
        if(!query.get('page')){
            params.set('page', 1);
        }
        fetchProducts()
    }, [params, query.get('categories[]')])

    const handlePageClick = (data) => {
        query.set('page', data.selected + 1);
        setParams(query);
        
        navigate({
            search: query.toString()
        });
    };

    const setFilterData = (filterString) => {
        let newParams = new URLSearchParams(filterString);
        if (query.get('order')) {
            newParams.set('order', query.get('order'))
        }
        setParams(newParams);
    };
  return (
    <section id="menu-6" className="wide-70 menu-section division">
        <div className="container">
            <div className="row">
                <CatalogCategories setFilterData={setFilterData}/>
                {loading ?
                    <div className="isLoading"></div>
                    :
                    <>
                        {items?.items?.map((card, index) =>
                            <ProductCard card={card} key={index}/>
                        )}
                    </>
                }
                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
            </div>
        </div>
    </section>
  )
})

export default CatalogContainer
import React from 'react'
import Fancybox from './FancyBox'

const Gallery = ({elem}) => {
  return (
    <section id="gallery-2" className="gallery-section division">
        <div className="container">
            <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                    <div className="section-title mb-60 text-center">	
                        <h2 className="h2-xl red-color">{elem.title}</h2>	
                        <div className="p-xl" dangerouslySetInnerHTML={{__html: elem.description}}></div>
                    </div>	
                </div>
            </div>
            <Fancybox
                    options={{
                        Carousel: {
                        infinite: false,
                        },
                    }}
                >
                    <div className="row">
                        {elem.elements?.map((card, index) =>
                            <div className="col-sm-6 col-lg-3" key={index}>
                                <div className="gallery-img">
                                    <a href={'https://solution-0002.panama.kz' + card} className="image-link" data-width="700" target="_blank" data-height="700" data-fancybox="gallery">
                                        <div className="hover-overlay"> 
                                            <img className="img-fluid" src={'https://solution-0002.panama.kz' + card} alt="gallery-image" />			
                                            <div className="item-overlay"></div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
            </Fancybox>
        </div>
    </section>
  )
}

export default Gallery
import React from 'react'
import { useFormContext } from 'react-hook-form';

const DeliveryOptionItem = ({card, setDelivery}) => {
    const {register, formState: {errors}} = useFormContext();

  return (
    <div className="delivery-option">
        <label className="form-check-label">
            <input className="form-check-input" onClick={() => setDelivery(card)} type="radio" name="standard" required value={card.id} {...register('delivery_id', {required: true})}/>
            {card.name}
        </label>
    </div>
  )
}

export default DeliveryOptionItem
import React from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import CatalogServices from '../../services/general/catalogServices';
import ProductCard from '../simple/ProductCard';
import { useContext } from 'react';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const RelatedProducts = observer(({ids}) => {
    const {app} = useContext(Context);

    const { 
        items,
    } = useFetchItems(CatalogServices.products.index, ids);

  return (
    <section id="menu-6" className="bg-lightgrey wide-70 menu-section division">
        <div className="container">
            <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                    <div className="section-title mb-60 text-center">	
                        <h2 className="h2-xl">{app.localizationsItems?.related_products}</h2>	
                        <p className="p-xl">{app.localizationsItems?.related_products_content}</p>
                            
                    </div>	
                </div>
            </div>
            <div className="row">
                {items?.items?.map((card, index) =>
                    <ProductCard card={card} key={index}/>
                )}
            </div>
        </div>
    </section>
  )
})

export default RelatedProducts
import React from 'react'
import CartContainer from '../../components/complex/CartContainer'
import PageTop from '../../components/simple/PageTop'
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Context } from '../..';

const Cart = observer(() => {
    const {app} = useContext(Context);

    const item = {
        title: app.localizationsItems?.basket
    }

  return (
    <div>
        <PageTop item={item}/>
        <CartContainer/>
    </div>
  )
})

export default Cart
import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const CheckoutAddress = observer(() => {
    const {app} = useContext(Context);

    const {register, formState: {errors}} = useFormContext();
  return (
    <>
        <div className="checkout-icon">
            <i className='fa-solid fa-location-dot'/>
        </div>
        <div className='checkout-box'>
            <div className="checkout-title">
                <h4>{app.localizationsItems?.delivery_address}</h4>
            </div>
            <div className="form-floating mb-4 theme-form-floating">
                <input type="text" className="form-control" {...register('city')} placeholder={app.localizationsItems?.city}/>
                <label>{app.localizationsItems?.city}</label>
            </div>
            <div className="form-floating mb-4 theme-form-floating">
                <input type="text" className="form-control" {...register('street')} placeholder={app.localizationsItems?.street}/>
                <label>{app.localizationsItems?.street}</label>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" {...register('house')} placeholder={app.localizationsItems?.house}/>
                        <label>{app.localizationsItems?.house}</label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" {...register('apartment')} placeholder={app.localizationsItems?.apartment}/>
                        <label>{app.localizationsItems?.apartment}</label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" {...register('entrance')} placeholder={app.localizationsItems?.entrance}/>
                        <label>{app.localizationsItems?.entrance}</label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" {...register('floor')} placeholder={app.localizationsItems?.floor}/>
                        <label>{app.localizationsItems?.floor}</label>
                    </div>
                </div>
            </div>
            <div className="form-floating mb-4 theme-form-floating">
                <textarea type="text" className="form-control" {...register('note')} placeholder={app.localizationsItems?.note}/>
                <label>{app.localizationsItems?.note}</label>
            </div>
        </div>
    </>
  )
})

export default CheckoutAddress
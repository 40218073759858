import React from 'react'
import Utils from '../../services/utils'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { Context } from '../..'
import { useState } from 'react'
import ToCartModal from '../modals/ToCartModal'
import UserServices from '../../services/general/userServices'

const BestOfferCard = observer(({card}) => {
    const {app, user} = useContext(Context);

    const [isFavorite, setIsFavorite] = useState(card.is_favorite);

    const [show, setShow] = useState();

    const addToWishlist = async () => {
        setIsFavorite(true);
        const newData = {
            uid: user.wishlistUid ? user.wishlistUid : null,
            product_id: card.id
        }

        const res = await UserServices.favorites.toggle(newData);

        if (res.statusCode === 200) {
            if(!user.wishlistUid){
                user.setWishlistUid(res.content.uid);
            }
        }else{
            setIsFavorite(false)
        }
    }

  return (
    <div className="col-lg-4">
        <div className="pbox-7 clearfix">
            <div className="pbox-7-img rel">
                <div className="hover-overlay"> 	
                    <img className="img-fluid" src={'https://solution-0002.panama.kz' + card.image} alt="promo-image" />
                    {card.state && <span className="item-code" style={{backgroundColor: card.state.subtitle}}>{card.state.name}</span>}
                    <div className="menu-img-zoom ico-25">
                        <a href="images/img-01.jpg" className="image-link">
                            <span className="flaticon-zoom"></span>
                        </a>
                    </div> 
                </div>
                <div className="like-ico ico-25" onClick={addToWishlist}>
                    <span className={isFavorite ? "flaticon-like active" : "flaticon-like"}></span>
                </div>	
            </div>
            <div id="pb-7-1" className="pbox-7-txt rel">
                <h4 className="h4-xs coffee-color">{card.title}</h4>
                <p className="grey-color">{card.subtitle}</p>
                <div className="pbox-7-price">
                    <h5 className="h5-lg red-color">{Utils.numberWithSpaces(card.old_price) && <span className="old-price">{Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</span>} {Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</h5>
                </div>
                <button className="add-to-cart bg-yellow ico-10" onClick={() => setShow(true)}>
                    <span className="flaticon-shopping-bag"></span>{app.localizationsItems?.add_to_cart}
                </button>
            </div>
        </div>
        {show && <ToCartModal show={show} setShow={setShow} slug={card.slug}/>}
    </div>
  )
})

export default BestOfferCard
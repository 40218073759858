import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';

const EventCard = observer(({card}) => {
    const {app} = useContext(Context);

  return (
    <div className="col-md-6 col-lg-4">
        <div className="blog-post">
            <div className="blog-post-img">
                <div className="hover-overlay"> 
                    <img className="img-fluid" src={'https://solution-0002.panama.kz' + card.image} alt="blog-post-image" />
                </div>	
            </div>
            <div className="blog-post-txt">
                <h5 className="h5-lg coffee-color"><NavLink to={`/${app.lang}/articles/${card.slug}`}>{card.title}</NavLink></h5>
                <p className="grey-color">{card.description}</p>
            </div>
        </div>
    </div>
  )
})

export default EventCard
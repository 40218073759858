import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Context } from '../../..'
import { Link, NavLink, Route, Routes } from 'react-router-dom';
import ActiveOrders from './ActiveOrders';

const Orders = observer(() => {
    const {app} = useContext(Context);
  return (
    <div className='profile-dashboard'>
        <h4>{app.localizationsItems?.my_orders}</h4>
        <div className='order-link-wrapper'>
            <div className='order-link active'>{app.localizationsItems?.active_orders}</div>
            <NavLink to={`/${app.lang}/user/orders-history`} className='order-link'>{app.localizationsItems?.history}</NavLink>
        </div>
        <ActiveOrders/>
    </div>
  )
})

export default Orders
import React, { useContext } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form';
import UserServices from '../../services/general/userServices';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';

const AddToBasket = observer(({item}) => {
    const {app, user} = useContext(Context);

    const [count, setCount] = useState(1);

    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const [visible, setVisible] = useState(false);

    const changeCount = (e) => {
        if(e.target.value >= 0){
            setCount(e.target.value)
        }
    }

    const addToBasket = async(data) => {
        let newData = {
            uid: user.uid ? user.uid : null,
            product_id: item.id,
            count: count,
            fields: []
        }

        for(var key in data){
            newData.fields.push(
                {name: key, value: data[key]}
            )
        }
          
        const res = await UserServices.cart.addToCart(newData);

        if (res.statusCode === 200) {
            setVisible(true);
            if(!user.uid){
                res.content.uid && user.setUid(res.content.uid);
            }
            user.setBasketChange(Math.random().toString(16))
        }else{
            toast.error(res.message)
        }

        setTimeout(() => {
            setVisible(false)
        }, 3000);
    }

  return (
    <form className='product-wrapper' onSubmit={handleSubmit(addToBasket)}>
        {item?.traits?.map((it, index) =>
            <div key={index}>
                <div className='product-title'> {it.title}</div>
                <div className='product-flex'>
                    {it.elements?.map((el, index) =>
                        <label key={index} className='product-label'>
                            <input  type="radio" {...register(it.title)} required  value={el.name}/>
                            <span>{el.name}</span>
                        </label>
                    )}
                </div>
            </div>
        )}
        <input className="qty" type="number" min="1" max="20" value={count} onChange={e => changeCount(e)}/>
        {((item.availability && (item.amount && item.amount > 0) || (item.availability && !item.amount))) ?
            <button className="add-to-cart-btn bg-yellow ico-20 text-center">
                <span className="flaticon-shopping-bag"></span>{app.localizationsItems?.add_to_cart}
            </button>
            :
            <button className="add-to-cart-btn bg-yellow ico-20 text-center" disabled={true}>
                <span className="flaticon-shopping-bag"></span>{app.localizationsItems?.add_to_cart}
            </button>
        }
        {visible && <h5 className='add-cart-item'>
            <i className='iconly-Buy'></i>
            {app.localizationsItems?.add_product}</h5>
        }
    </form>
  )
})

export default AddToBasket
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import AuthServices from '../../services/general/authServices';
import { Context } from '../..';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const RegistrationContainer = observer(() => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState();

    const {app} = useContext(Context);

	const {handleSubmit, register, control, formState: {errors}} = useForm();

	const CustomSubmit = async (data) => {
        setLoading(true);

        const res = await AuthServices.register(data);

        if (res.statusCode === 200) {
            navigate(`/${app.lang}`);
            toast.success(res.message);
        }else{
			toast.error(res.message);
		}
        setLoading(false);
    }

  return (
    <div id="booking-2" className="wide-70 booking-section division">
        <div className="container">
            <div className="row">
                <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                    <div className="form-holder">
                        <p className="p-xl text-center">
                            <h3>{app.localizationsItems?.regis_title}</h3>
                            {app.localizationsItems?.regis_content}
                        </p>
                        <form className="row justify-content-end booking-form" onSubmit={handleSubmit(CustomSubmit)}>
                            <div className="col-lg-12">
                                <input {...register('name')} required type="text" className="form-control email" placeholder={app.localizationsItems?.name}/> 
                            </div>
                            <div className="col-lg-12">
                                <input {...register('email')} required type="email" className="form-control email" placeholder={app.localizationsItems?.email}/> 
                            </div>
                            <div className="col-lg-12">
                                <input {...register('password')} required type="password" className="form-control phone" placeholder={app.localizationsItems?.password}/> 
                            </div>     
                            <div className="col-lg-12">
                                <input {...register('password_confirmation')} required type="password" className="form-control phone" placeholder={app.localizationsItems?.confirm_password}/> 
                            </div>     
                            <div className="col-auto mt-10">  
                                <button type="submit" className="btn btn-md btn-red tra-red-hover submit">{app.localizationsItems?.register}</button> 
                            </div>                                     
                        </form>
                        <div className="row justify-content-center b-top pt-20 mt-20">
                            <div className="col-auto">
                                <div className="sign-up-box text-center">
                                    <h4>{app.localizationsItems?.have_account}</h4>
                                    <NavLink to={`/${app.lang}/login`}>{app.localizationsItems?.login}</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>	
                </div>	
            </div>
        </div>
    </div>
  )
})

export default RegistrationContainer